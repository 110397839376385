

import jazzicon from 'jazzicon'

  # const numericRepresentation = jsNumberForAddress(address);
  # const identicon = this.jazzicon(diameter, numericRepresentation);

jsNumberForAddress = (address) -> parseInt address[2...10], 16

export default
  props:
    size: Number
    address: String
  computed:
    number: -> parseInt @address[2...10], 16
    animalNumber: -> @number % 64
    colorNumber: -> Math.floor(@number / 64)
    animal: -> ANIMALS[@animalNumber]
    color: -> COLORS[@colorNumber]
    # name: -> ANIMALS_RU[@animalNumber]
  render: (h) ->
    el = jazzicon(@size, @number)
    h "div", 
      attrs:
        style: el.getAttribute 'style'
      domProps: 
        innerHTML: el.innerHTML
  mounted: ->
    # console.info @number
    # window.el = jazzicon(@size, @number)
    # console.log(el)
    # console.log("##{@color}")




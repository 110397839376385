import config from '../config.coffee'
import { Message, MessageBox } from 'element-ui'
import {
  compressToUTF16 as compress
  decompressFromUTF16 as decompress
} from 'lz-string'

import {
  padStart
  capitalize
} from 'lodash'

import jsonpack from 'jsonpack'

window.compressToUTF16 = compress
window.decompressFromUTF16 = decompress
window.jsonpack = jsonpack

# import store from '../store.coffee'

# import promiseAllSequential from 'promise-all-sequential'

# export PromiseGroup = if config.tronLink.network == 'nile'
#   Promise.all
# else
#   promiseAllSequential

sleep = (ms) -> new Promise((resolve) -> setTimeout(resolve, ms))

MAX_QPS = 10
QPS = 0

setInterval((->
  QPS -= MAX_QPS
  QPS = 0 if QPS < 0
), 1000)

export repeat = (f, n=0) ->
  return await f() # remove this line for repeats
  try
    # console.info 'call'
    # console.info QPS
    await sleep(100) until QPS < MAX_QPS
    QPS++
    return await f()
  catch error
    n++
    if n < 50
      await sleep 500 + Math.round(Math.random()*1000)
      return repeat f, n+1
    else
      console.error("after #{n-1} tries")
      throw error

stripEvent = (event) ->
  delete event.block
  delete event.resourceNode
  delete event.contract
  delete event.unconfirmed
  delete event.result[i] for i in [0..(Object.keys(event.result).length/2)]

export getAllContractEvents = if false #config.tronLink.network == 'nile' or config.tronLink.network == 'local'
  (address) ->
    sort = 'block_timestamp'
    list = await tronWeb.getEventResult(address, {size: 1, sort})
    return [] if list.length == 0
    fingerprint = list[0].fingerprint
    result = []
    loop
      list = await tronWeb.getEventResult(address, {size: 200, sort, fingerprint})
      break if list.length == 0
      result.push(...list)
      fingerprint = list[list.length-1].fingerprint
    return result
else
  # (address) ->
  #   sort = 'block_timestamp'
  #   result = await tronWeb.getEventResult(address, {size: 200, sort})
  #   return [] if result.length == 0
  #   fingerprint = result[result.length-1].fingerprint
  #   # console.info fingerprint
  #   loop
  #     break unless fingerprint?
  #     # console.info fingerprint
  #     part = await tronWeb.getEventResult(address, {size: 200, sort, fingerprint})
  #     break if part.length == 0
  #     result.push(...part)
  #     fingerprint = result[result.length-1].fingerprint
  #   return result
  (address) ->
    size = 200
    sinceTimestamp = 0
    sort = 'block_timestamp'
    fingerprint = null
    list = []
    allEvents = decompress localStorage.getItem "events"
    allEvents = '^^^$]' if allEvents == ''
    allEvents = jsonpack.unpack allEvents
    events = allEvents[address] or []
    address = tronWeb.address.fromHex(address) if address.length == 42

    if events.length > 0
      sinceTimestamp = events[events.length - 1].timestamp + 1000

    loop
      # console.info address, {size, sort, sinceTimestamp, fingerprint}, "\n"
      results = await repeat(-> tronWeb.getEventResult address, {size, sort, sinceTimestamp, fingerprint})
      break if results.length == 0
      last = results[results.length - 1]

      if last.fingerprint
        {fingerprint} = last
        delete last.fingerprint
        list.push ...results
        if list.length > 800
          sinceTimestamp = last.timestamp
          list = list.filter( (e) -> e.timestamp != sinceTimestamp )
          if list.length == 0
            throw 'CRITICAL ERROR'
          list.forEach stripEvent
          events.push ...list
          console.info "[#{address}] #{list.length} events loaded (#{events.length} total)"
          list = []
          fingerprint = null
      else
        list.forEach stripEvent
        results.forEach stripEvent
        events.push ...list, ...results
        console.info "[#{address}] #{list.length + results.length} events loaded (#{events.length} total)"
        list = []
        sinceTimestamp = last.timestamp + 1000
        fingerprint = null

    try
      allEvents[address] = events
      localStorage.setItem("events", compress jsonpack.pack allEvents)

    events


export mutate = (field) -> (state, value) -> state[field] = value

export singularMutations = (fields) ->
  result = {}
  for field in fields
    result[field] = mutate(field)
  return result

export isValidAddress = (address) ->
  try
    hex = tronWeb.address.toHex(address)
    return address == tronWeb.address.fromHex(hex)
  catch
    return false

export decodeRevertMessage = (s) ->
  len = parseInt(s.slice(72, 136), 16)
  s.slice(136, 136+len*2).match(/.{1,2}/g).map (hexByte) ->
    String.fromCharCode parseInt hexByte, 16
  .join ''

export getTransactionResult = (id) ->
  sleep = (ms) -> new Promise((resolve) -> setTimeout(resolve, ms))
  # t0 = +new Date
  loop
    info = await tronWeb.trx.getUnconfirmedTransactionInfo(id)
    # console.info info
    break if info.id == id
    await sleep 1000
  # console.info(+new Date - t0, info)
  info

export verboseSend = (contractCall, options={}) ->
  # throw 'REMOVE THIS CALL'
  msg = Message(message: 'Sending transaction', duration: 0, iconClass: 'el-message__icon el-icon-loading')
  # repeat(-> contractCall.send(options))
  contractCall.then (tx) =>
    msg.message = 'Awaiting receipt'
    receipt = await tx.wait()
    p receipt
    msg.type = 'success'
    msg.iconClass = ''
    msg.message = 'Success'
    msg.duration = 2000
    msg.startTimer()
    msg
    # getTransactionResult(tx).then (info) ->
    #   if info.result == "FAILED"
    #     throw 'Не хватило энергии' if info.receipt.result == "OUT_OF_ENERGY"
    #     if info.receipt.result == "REVERT"
    #       # console.info info
    #       if !!info.contractResult[0]
    #         throw decodeRevertMessage(info.contractResult[0])
    #       else throw "REVERT"
    #     throw "ILLEGAL_OPERATION" if info.receipt.result == "ILLEGAL_OPERATION"
    #     throw "Превышено время выполнения" if info.receipt.result == "OUT_OF_TIME"
    #     throw 'Неизвестная ошибка'
    #   else
    #     msg.type = 'success'
    #     msg.iconClass = ''
    #     msg.message = 'Рекордный успех'
    #     msg.duration = 2000
    #     msg.startTimer()
    #     return msg
  .catch (e) ->
    # window.le = e
    msg.type = 'error'
    msg.iconClass = ''
    message = e.reason
    message = e.message if !!message and e.message and e.message.length < 100
    if message
      msg.message = capitalize(message)
    else
      window.e = e
      msg.message = 'Unhandled error'
    msg.showClose = true
    throw e

export checkTrxBalance = (userAddress, amount) ->
  console.info 'checkTrxBalance'
  new Promise (resolve, reject) -> tronWeb.trx.getAccount(userAddress).then ({balance}) ->
    if tronWeb.BigNumber(amount).gt tronWeb.BigNumber(balance)
      MessageBox.alert "На вашем счете недостаточно TRX для совершения операции", type: 'error'
      resolve(false)
    else
      resolve(true)

export checkTokenBalance = (userAddress, amount, tokenSymbol, contract) ->
  console.info 'checkTokenBalance'
  console.info tokenSymbol, config.contracts.TRC20[tokenSymbol]
  # contract = await tronWeb.contract().at(config.contracts.TRC20[tokenSymbol])
  # window.c = contract
  new Promise (resolve, reject) -> contract.balanceOf(userAddress).call().then (balance) ->
    if tronWeb.BigNumber(amount).gt tronWeb.BigNumber(balance)
      MessageBox.alert "На вашем счете недостаточно #{tokenSymbol} для совершения операции", type: 'error'
      resolve(false)
    else
      resolve(true)

import BigNumber from 'bignumber.js'

export roundBy = (value, divisor) -> value.div(divisor).integerValue(BigNumber.ROUND_HALF_UP).times(divisor)

import {address2hex, Multicall} from '../multicall.coffee'

# import { zipObject } from "lodash"
import { zipObject, mapValues } from "lodash"

export getTokenBalances = (account) ->
  multicall = Multicall(tronWeb, config.contracts.Multicall)

  symbols = []

  calls = for symbol, contractAddress of config.contracts.TRC20
    symbols.push symbol
    contract: address2hex(contractAddress)
    signature: '0x70a08231'
    inputs: ["address"]
    outputs: ["uint256"]
    parameters: [address2hex(account)]

  calls.push
    contract: address2hex(config.contracts.Multicall)
    signature: '0x96c610e1'
    inputs: ["address"]
    outputs: ["uint256"]
    parameters: [address2hex(account)]

  symbols.push "TRX"

  results = await multicall(calls)
  zipObject(symbols, results)
  # zipObject(symbols, results.map((e) -> +tronWeb.fromSun(e.toString())))

window.balances = (addr, token=null) ->
  if token
    (await window.balances(addr))[token]
  else
    mapValues (await getTokenBalances(addr)), (val) -> +tronWeb.fromSun(val.toString())

import _humanizeDuration from 'humanize-duration'

export humanizeDuration = (period) ->
  _humanizeDuration period,
    language: "ru"
    largest: 1
    units: ["d", "h", "m", "s", "ms"]
    round: true

import { formatWithOptions } from 'date-fns/fp'
import { ru } from 'date-fns/locale'

export formatDate = formatWithOptions locale: ru


import { invert } from 'lodash'

# export tokenSymbol = invert(config.contracts.TRC20)

export addressFromBN = (bn) -> tronWeb.address.fromHex '0x'+padStart(bn.toHexString().slice(2), 40, '0')
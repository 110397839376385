
import NavigationLong from './components/NavigationLong.vue'
import NavigationShort from './components/NavigationShort.vue'
import NavigationMobile from './components/NavigationMobile.vue'
import { OrbitSpinner, HollowDotsSpinner } from 'epic-spinners'

import { mapGetters, mapState } from 'vuex'
import config from './config.coffee'

# import Tron from './control/Tron.vue'
# import KingOfTheHill from './control/KingOfTheHill.vue'
# import Cashback from './control/Cashback.vue'
# import Staking from './control/Staking.vue'
import Exchange from './control/Exchange.vue'
# import Cabinet from './control/Cabinet.vue'
# import TokenMarket from './control/TokenMarket.vue'
# import Binar from './control/Binar.vue'

export default
  name: "app"
  components: {
    OrbitSpinner
    HollowDotsSpinner
    NavigationLong, NavigationShort, NavigationMobile
    # Tron
    # KingOfTheHill
    # Cashback
    # Staking
    Exchange
    # Cabinet
    # TokenMarket
    # Binar
  }
  data: ->
    showControlPanel: false
  computed: {
    ...mapState 'Wallet', userAddress: (state) -> state.account.address
    # ...mapGetters 'TronLink', tronLink: 'flags'
    ...mapState ['loading']
    sectionName: -> 'Exchange' #@$route.name.split(':')[0]
    showControl: -> (config.control.permissions[@sectionName] || []).includes(@userAddress)
    controlComponent: -> config.control.map[@sectionName]
  }
  mounted: ->
    # p config.control.permissions[@sectionName]
    # p @userAddressa
    # console.info @controlComponent
  methods: {}



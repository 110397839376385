

import {mapState, mapGetters} from 'vuex'
import PerfectScrollbar from 'perfect-scrollbar';

export default
  computed: {
    ...mapGetters 'Exchange', [
      'asks'
      'currentPair'
      'userAddress'
    ]
  }
  mounted: ->
    @ps = new PerfectScrollbar @$el.getElementsByClassName('el-table__body-wrapper')[0]
  beforeDestroy: ->
    @ps.destroy()
    @ps = null

// import 'typeface-roboto/index.css'

import config from './config.coffee'
import Vue from 'vue'
import App from './App.vue'
import router from './router.js'
import store from './store.coffee'
import './element-ui.coffee'

import VueScreen from 'vue-screen'
import VueCountdownTimer from 'vuejs-countdown-timer'
import Clipboard from 'v-clipboard'
import VBurger from 'vue-burger'

import './element-variables.scss'
import './perfect-scrollbar.css'

window.config = config
window.store = store

window.p = console.info

Vue.use(VueScreen, config.breakpoints)
Vue.use(VueCountdownTimer)
Vue.use(Clipboard)
Vue.use(VBurger)

Vue.config.productionTip = false


store.dispatch('initialize').then(() => {
  new Vue({
    router: router(store),
    store,
    render: h => h(App)
  }).$mount('#app')
})

import BigNumber from 'bignumber.js'
window.BigNumber = BigNumber

BigNumber.config({ EXPONENTIAL_AT: 78 })
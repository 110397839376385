import deployed from "../../contracts/bsc/deployed.json"
export network = 'testnet'
# export network = 'localhost'
export FACTORY_ADDRESS = deployed[network].factory.address
export MULTICALL_ADDRESS = deployed[network].multicall.address

export default
  control:
    permissions:
      'Exchange': [
        '0xBA46C6f961a919b28bc32B5C20cA0190C9388A9F'
      ]
    map:
      'Exchange': 'Exchange'
import Vue from 'vue'
import Vuex from 'vuex'
import config from "./config.coffee"

Vue.use Vuex


import Wallet from './store/Wallet.coffee'
import contracts from './store/contracts.coffee'
# import Tron from './store/Tron.coffee'
# import KingOfTheHill from './store/KingOfTheHill.coffee'
# import Cashback from './store/Cashback.coffee'
# import Staking from './store/Staking.coffee'
import Exchange from './store/Exchange.coffee'
# import InvestBox from './store/InvestBox.coffee'
# import TokenMarket from './store/TokenMarket.coffee'
# import Binar from './store/Binar.coffee'
# import Cabinet from './store/Cabinet.coffee'
import Events from './store/Events.coffee'

import {isValidAddress, mutate} from './store/functions.coffee'

LOCALSTORAGE_VERSION = '2021-12-09'

$store = new Vuex.Store
  modules: {Wallet, Exchange, Events, contracts}
  state:
    pairs: []
    loading:
      inProgress: false
      message: ''
  mutations:
    referrer: mutate('referrer')
    load: (state, msg) -> 
      state.loading.inProgress = true
      state.loading.message = msg
    finish: (state, value) -> 
      state.loading.inProgress = false
      state.loading.message = ''
  actions:
    initialize: ({dispatch}) ->
      if localStorage.version != LOCALSTORAGE_VERSION
        localStorage.clear()
        localStorage.version = LOCALSTORAGE_VERSION
      await dispatch('Wallet/initialize')
      await Promise.all [
        dispatch('Events/initialize')
        dispatch('contracts/initialize')
      ]
      # res = await fetch("/events/#{FACTORY_ADDRESS}.json")
      # {events} = await res.json()
      # for {name, } in events when event.name == 'PairCreated'


export watch = $store.watch

# p = console.info

# p $store.watch((
#   (state) -> 
#     p state.Exchange.currentPair
#     p Object.keys(state.Events.events)
#     p state.Events.events[state.Exchange.currentPair]?.length
#     state.Events.events[state.Exchange.currentPair]?.length
# ), (
#   (value) -> p value
# ), deep: true)

export default $store
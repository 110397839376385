

import {mapState, mapGetters} from 'vuex'
import PerfectScrollbar from 'perfect-scrollbar';
import dateformat from 'dateformat'

# row = ->
#   time: (new Date(Math.random()*24*3600*1000)).toLocaleTimeString()
#   price: (Math.random() + 1).toFixed(2)
#   ABC: (Math.random()*10000).toFixed(0)
#   XYZ: (Math.random()*10000).toFixed(0)
#   sell: Math.random() > 0.5
export default
  data: ->
    tab: "all"
    # history: Array.from(Array(20)).map row
  computed: {
    ...mapGetters 'Exchange', [
      'orders'
      'currentPair'
      'userAddress'
      'trades'
      'myTrades'
    ]
    ...mapState 'Exchange', ['contract']
  }
  mounted: ->
    @ps1 = new PerfectScrollbar @$el.getElementsByClassName('el-table__body-wrapper')[0]
    @ps2 = new PerfectScrollbar @$el.getElementsByClassName('el-table__body-wrapper')[1]
  methods: {dateformat}
  beforeDestroy: ->
    @ps1.destroy()
    @ps1 = null
    @ps2.destroy()
    @ps2 = null


import { mapState, mapActions, mapGetters } from 'vuex'
# import TronAddress from './TronAddress.vue'
import AddressIcon from './AddressIcon.vue'
import {network} from '../config.coffee'

export default
  components: {AddressIcon}
  props:
    showControl: false
  data: ->
    activeLink: "/"
  beforeRouteEnter: (to, from, next) -> next((vm) => vm.activeLink = to.path)
  beforeRouteUpdate: (to, from, next) ->
    @activeLink = to.path
    next()
  computed: {
    ...mapState 'Wallet', address: (state) -> state.account.address
    ...mapGetters 'Wallet', ['shortAddress']
    ...mapState 'Events', ['pairs']
    contractAddress: -> @$store.getters["Events/addressOf"][@$store.state.Exchange.currentPair]
    contractLink: -> switch network
      when 'localhost' then "about:blank##{@contractAddress}"
      when 'testnet' then "https://testnet.bscscan.com/address/#{@contractAddress}"
      when 'mainnet' then "https://bscscan.com/address/#{@contractAddress}"
  }
  mounted: ->
    @activeLink = @$route.path
    # p @contractLink
  methods: {
    ...mapActions 'Wallet', ['connect', 'disconnect']
  }

import { Message } from 'element-ui'

export default
  computed:
    currentIndex: -> @dlayouts.findIndex (l) => l.breakpoint == @currentBreakpoint
    currentNumberOfCols:
      get: -> @dlayouts[@currentIndex].numberOfCols
      set: (val) ->
        @dlayouts[@currentIndex].numberOfCols = val
    currentRowHeight:
      get: -> @dlayouts[@currentIndex].rowHeight
      set: (newValue) ->
        @dlayouts[@currentIndex].rowHeight = newValue
    currentBreakpointWidth: -> @dlayouts[@currentIndex].breakpointWidth
    codeOfLayouts: ->
      return unless @editMode
      code = "export default ["
      for layout in @dlayouts
        code += "{\n"
        code += "\tbreakpoint: '#{layout.breakpoint}'\n" if layout.breakpoint
        code += "\tbreakpointWidth: #{layout.breakpointWidth}\n" if layout.breakpointWidth
        code += "\tuseCssTransforms: #{layout.useCssTransforms}\n" if layout.useCssTransforms
        code += "\tnumberOfCols: #{layout.numberOfCols}\n" if layout.numberOfCols
        code += "\tmargin: #{JSON.stringify layout.margin}\n" if layout.margin
        code += "\tcompact: #{layout.compact}\n" if layout.compact
        code += "\tcolWidth: #{layout.colWidth}\n" if layout.colWidth
        code += "\tmaxColWidth: #{layout.maxColWidth}\n" if layout.maxColWidth
        code += "\tminColWidth: #{layout.minColWidth}\n" if layout.minColWidth
        code += "\trowHeight: #{layout.rowHeight}\n" if layout.rowHeight
        code += "\tmaxRowHeight: #{layout.maxRowHeight}\n" if layout.maxRowHeight
        code += "\tminRowHeight: #{layout.minRowHeight}\n" if layout.minRowHeight
        code += "\titems: [\n"
        for item in layout.items
          code += "\t\t{ x: #{item.x}, y: #{item.y}, width: #{item.width}, height: #{item.height}, id: #{item.id}, component: '#{item.component}'"
          code += ", minWidth: #{item.minWidth}" if item.minWidth
          code += ", maxWidth: #{item.maxWidth}" if item.maxWidth
          code += ", minHeight: #{item.minHeight}" if item.minHeight
          code += ", maxHeight: #{item.maxHeight}" if item.maxHeight
          code += "}\n"
        code += "\t]\n}\n"
      code += "]"
      code
  methods:
    invalidBreakpoint: ->
      if @dlayouts.map((e) -> e.breakpointWidth).includes(@$screen.width) or @dlayouts.map((e) -> e.breakpoint).includes(@newBreakpoint)
         return true
      else
        return false
    createNewBreakpoint: ->
      newBreakpoint = JSON.parse JSON.stringify @dlayouts[@currentIndex]
      newBreakpoint.breakpoint = @newBreakpoint
      newBreakpoint.breakpointWidth = @$screen.width - 1
      if(@dlayouts.length == 1)
        @dlayouts[0].breakpointWidth = 1
      @dlayouts.push(newBreakpoint)
      @dlayouts.sort (a,b) -> a.breakpointWidth - b.breakpointWidth
    align: ->
      heightStep = @dlayouts[@currentIndex].rowHeight
      height = if heightStep > 4 then Math.ceil((100/heightStep + 1)/2) else 10
      width = Math.ceil(@dlayouts[@currentIndex].numberOfCols/3)
      for item, k in @dlayouts[@currentIndex].items
        item.x = 0; item.y = k*height;
        item.height = height; item.width = width
    switchEditMode: -> @editMode = !@editMode
    copyToClipboard: ->
      @$clipboard @codeOfLayouts
      Message message: "Код скопирован в буфер обмена", type: "success", duration: 2000
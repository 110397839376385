export default [{
	breakpoint: 'MobilS'
	breakpointWidth: 1
	numberOfCols: 12
	rowHeight: 10
	items: [
		{ x: 0, y: 0, width: 12, height: 19, id: 1, component: 'SellOrders'}
		{ x: 0, y: 44, width: 12, height: 19, id: 2, component: 'BuyOrders'}
		{ x: 0, y: 88, width: 12, height: 19, id: 3, component: 'TradeHistory'}
		{ x: 0, y: 107, width: 12, height: 25, id: 4, component: 'ActiveOrders'}
		{ x: 0, y: 19, width: 12, height: 25, id: 5, component: 'Sell'}
		{ x: 0, y: 63, width: 12, height: 25, id: 6, component: 'Buy'}
	]
}
{
	breakpoint: 'MobilM'
	breakpointWidth: 374
	numberOfCols: 12
	rowHeight: 10
	items: [
		{ x: 0, y: 0, width: 12, height: 19, id: 1, component: 'SellOrders'}
		{ x: 0, y: 44, width: 12, height: 19, id: 2, component: 'BuyOrders'}
		{ x: 0, y: 88, width: 12, height: 19, id: 3, component: 'TradeHistory'}
		{ x: 0, y: 107, width: 12, height: 25, id: 4, component: 'ActiveOrders'}
		{ x: 0, y: 19, width: 12, height: 25, id: 5, component: 'Sell'}
		{ x: 0, y: 63, width: 12, height: 25, id: 6, component: 'Buy'}
	]
}
{
	breakpoint: 'MobilL'
	breakpointWidth: 424
	numberOfCols: 12
	rowHeight: 10
	items: [
		{ x: 0, y: 0, width: 12, height: 19, id: 1, component: 'SellOrders'}
		{ x: 0, y: 44, width: 12, height: 19, id: 2, component: 'BuyOrders'}
		{ x: 0, y: 88, width: 12, height: 19, id: 3, component: 'TradeHistory'}
		{ x: 0, y: 107, width: 12, height: 25, id: 4, component: 'ActiveOrders'}
		{ x: 0, y: 19, width: 12, height: 25, id: 5, component: 'Sell'}
		{ x: 0, y: 63, width: 12, height: 25, id: 6, component: 'Buy'}
	]
}
{
	breakpoint: 'M'
	breakpointWidth: 767
	numberOfCols: 12
	rowHeight: 10
	items: [
		{ x: 0, y: 0, width: 6, height: 19, id: 1, component: 'SellOrders'}
		{ x: 6, y: 0, width: 6, height: 19, id: 2, component: 'BuyOrders'}
		{ x: 0, y: 44, width: 12, height: 19, id: 3, component: 'TradeHistory'}
		{ x: 0, y: 63, width: 12, height: 25, id: 4, component: 'ActiveOrders'}
		{ x: 0, y: 19, width: 6, height: 25, id: 5, component: 'Sell'}
		{ x: 6, y: 19, width: 6, height: 25, id: 6, component: 'Buy'}
	]
}
{
	breakpoint: 'L'
	breakpointWidth: 1108
	numberOfCols: 12
	rowHeight: 10
	items: [
		{ x: 0, y: 0, width: 3, height: 19, id: 1, component: 'SellOrders'}
		{ x: 3, y: 0, width: 3, height: 19, id: 2, component: 'BuyOrders'}
		{ x: 6, y: 0, width: 6, height: 19, id: 3, component: 'TradeHistory'}
		{ x: 6, y: 19, width: 6, height: 25, id: 4, component: 'ActiveOrders'}
		{ x: 0, y: 19, width: 3, height: 25, id: 5, component: 'Sell'}
		{ x: 3, y: 19, width: 3, height: 25, id: 6, component: 'Buy'}
	]
}
]

# export default [{
# 	breakpoint: 'minimal'
# 	numberOfCols: 12
# 	rowHeight: 10
# 	items: [
# 		{ x: 0, y: 0, width: 3, height: 19, id: 1, component: 'SellOrders'}
# 		{ x: 3, y: 0, width: 3, height: 19, id: 2, component: 'BuyOrders'}
# 		{ x: 6, y: 0, width: 6, height: 19, id: 3, component: 'TradeHistory'}
# 		{ x: 6, y: 19, width: 6, height: 25, id: 4, component: 'ActiveOrders'}
# 		{ x: 0, y: 19, width: 3, height: 25, id: 5, component: 'Sell'}
# 		{ x: 3, y: 19, width: 3, height: 25, id: 6, component: 'Buy'}
# 	]
# }
# ]
# }, {
#   breakpoint: 'lg'
#   breakpointWidth: 1200
#   numberOfCols: 6
#   items: [
#     { x: 0, y: 0, width: 1, height: 1, id:  1, component: 'Rewards' }
#     { x: 1, y: 0, width: 1, height: 1, id:  2, component: 'GamesPlayed' }
#     { x: 2, y: 0, width: 1, height: 1, id:  3, component: 'Players' }
#     { x: 3, y: 0, width: 1, height: 1, id:  4, component: 'YourReward' }
#     { x: 4, y: 0, width: 1, height: 1, id:  5, component: 'YourGames' }
#     { x: 5, y: 0, width: 1, height: 1, id:  6, component: 'PartnerGames' }
#     { x: 0, y: 1, width: 2, height: 1, id:  7, component: 'NextRewardProgress' }
#     { x: 2, y: 1, width: 2, height: 1, id:  8, component: 'Play' }
#     { x: 4, y: 1, width: 2, height: 1, id:  9, component: 'YourReward' }
#     { x: 0, y: 2, width: 3, height: 1, id: 10, component: 'MainQueue' }
#     { x: 3, y: 2, width: 3, height: 1, id: 11, component: 'PersonalQueue' }
#     { x: 0, y: 3, width: 3, height: 1, id: 12, component: 'CompletedGames' }
#     { x: 3, y: 3, width: 3, height: 1, id: 13, component: 'Partners' }
#   ]
# }, {
#   breakpoint: 'md'
#   breakpointWidth: 996
#   numberOfCols: 8
#   items: [
#     { x: 0, y: 0, width: 1, height: 1, id:  1, component: 'Rewards' }
#     { x: 0, y: 0, width: 1, height: 1, id:  2, component: 'GamesPlayed' }
#     { x: 0, y: 0, width: 1, height: 1, id:  3, component: 'Players' }
#     { x: 0, y: 0, width: 1, height: 1, id:  4, component: 'YourReward' }
#     { x: 0, y: 0, width: 1, height: 1, id:  5, component: 'YourGames' }
#     { x: 0, y: 0, width: 1, height: 1, id:  6, component: 'PartnerGames' }
#     { x: 0, y: 0, width: 1, height: 1, id:  7, component: 'NextRewardProgress' }
#     { x: 0, y: 0, width: 1, height: 1, id:  8, component: 'Play' }
#     { x: 0, y: 0, width: 1, height: 1, id:  9, component: 'YourReward' }
#     { x: 0, y: 0, width: 1, height: 1, id: 10, component: 'MainQueue' }
#     { x: 0, y: 0, width: 1, height: 1, id: 11, component: 'PersonalQueue' }
#     { x: 0, y: 0, width: 1, height: 1, id: 12, component: 'CompletedGames' }
#     { x: 0, y: 0, width: 1, height: 1, id: 13, component: 'Partners' }
#   ]
# }, {
#   breakpoint: 'sm'
#   breakpointWidth: 768
#   numberOfCols: 4
#   items: [
#     { x: 0, y: 0, width: 1, height: 1, id:  1, component: 'Rewards' }
#     { x: 0, y: 0, width: 1, height: 1, id:  2, component: 'GamesPlayed' }
#     { x: 0, y: 0, width: 1, height: 1, id:  3, component: 'Players' }
#     { x: 0, y: 0, width: 1, height: 1, id:  4, component: 'YourReward' }
#     { x: 0, y: 0, width: 1, height: 1, id:  5, component: 'YourGames' }
#     { x: 0, y: 0, width: 1, height: 1, id:  6, component: 'PartnerGames' }
#     { x: 0, y: 0, width: 1, height: 1, id:  7, component: 'NextRewardProgress' }
#     { x: 0, y: 0, width: 1, height: 1, id:  8, component: 'Play' }
#     { x: 0, y: 0, width: 1, height: 1, id:  9, component: 'YourReward' }
#     { x: 0, y: 0, width: 1, height: 1, id: 10, component: 'MainQueue' }
#     { x: 0, y: 0, width: 1, height: 1, id: 11, component: 'PersonalQueue' }
#     { x: 0, y: 0, width: 1, height: 1, id: 12, component: 'CompletedGames' }
#     { x: 0, y: 0, width: 1, height: 1, id: 13, component: 'Partners' }
#   ]
# }, {
#   breakpoint: 'xs'
#   breakpointWidth: 480
#   numberOfCols: 2
#   items: [
#     { x: 0, y: 0, width: 1, height: 1, id:  1, component: 'Rewards' }
#     { x: 0, y: 0, width: 1, height: 1, id:  2, component: 'GamesPlayed' }
#     { x: 0, y: 0, width: 1, height: 1, id:  3, component: 'Players' }
#     { x: 0, y: 0, width: 1, height: 1, id:  4, component: 'YourReward' }
#     { x: 0, y: 0, width: 1, height: 1, id:  5, component: 'YourGames' }
#     { x: 0, y: 0, width: 1, height: 1, id:  6, component: 'PartnerGames' }
#     { x: 0, y: 0, width: 1, height: 1, id:  7, component: 'NextRewardProgress' }
#     { x: 0, y: 0, width: 1, height: 1, id:  8, component: 'Play' }
#     { x: 0, y: 0, width: 1, height: 1, id:  9, component: 'YourReward' }
#     { x: 0, y: 0, width: 1, height: 1, id: 10, component: 'MainQueue' }
#     { x: 0, y: 0, width: 1, height: 1, id: 11, component: 'PersonalQueue' }
#     { x: 0, y: 0, width: 1, height: 1, id: 12, component: 'CompletedGames' }
#     { x: 0, y: 0, width: 1, height: 1, id: 13, component: 'Partners' }
#   ]
# }, {
#   breakpoint: 'xxs'
#   breakpointWidth: 0
#   numberOfCols: 1
#   items: [
#     { x: 0, y: 0, width: 1, height: 1, id:  1, component: 'Rewards' }
#     { x: 0, y: 0, width: 1, height: 1, id:  2, component: 'GamesPlayed' }
#     { x: 0, y: 0, width: 1, height: 1, id:  3, component: 'Players' }
#     { x: 0, y: 0, width: 1, height: 1, id:  4, component: 'YourReward' }
#     { x: 0, y: 0, width: 1, height: 1, id:  5, component: 'YourGames' }
#     { x: 0, y: 0, width: 1, height: 1, id:  6, component: 'PartnerGames' }
#     { x: 0, y: 0, width: 1, height: 1, id:  7, component: 'NextRewardProgress' }
#     { x: 0, y: 0, width: 1, height: 1, id:  8, component: 'Play' }
#     { x: 0, y: 0, width: 1, height: 1, id:  9, component: 'YourReward' }
#     { x: 0, y: 0, width: 1, height: 1, id: 10, component: 'MainQueue' }
#     { x: 0, y: 0, width: 1, height: 1, id: 11, component: 'PersonalQueue' }
#     { x: 0, y: 0, width: 1, height: 1, id: 12, component: 'CompletedGames' }
#     { x: 0, y: 0, width: 1, height: 1, id: 13, component: 'Partners' }
#   ]
# }]

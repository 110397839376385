
import { mapState, mapGetters, mapActions } from 'vuex'
import AddressIcon from './AddressIcon.vue'
import {network} from '../config.coffee'

import { VBurger } from 'vue-burger'

export default
  components: {AddressIcon, VBurger}
  props:
    showControl: false
  data: ->
    activeLink: "/"
    showDrawer: false
    # burgerStyle:
      # '--padding': '10px'
      # '--layer-bg-color': 'yellow'
      # '--active-layer-bg-color': '#ABABAB'
      # '--layer-width': '20px'
  beforeRouteEnter: (to, from, next) -> next((vm) => vm.activeLink = to.path)
  beforeRouteUpdate: (to, from, next) ->
    @activeLink = to.path
    next()
  computed: {
    ...mapState 'Wallet', address: (state) -> state.account.address
    ...mapGetters 'Wallet', ['shortAddress']
    ...mapState 'Events', ['pairs']
    contractAddress: -> @$store.getters["Events/addressOf"][@$store.state.Exchange.currentPair]
    contractLink: -> switch network
      when 'localhost' then "about:blank##{@contractAddress}"
      when 'testnet' then "https://testnet.bscscan.com/address/#{@contractAddress}"
      when 'mainnet' then "https://bscscan.com/address/#{@contractAddress}"
  }
  mounted: ->
    @activeLink = @$route.path
    # console.info(@$route)
  methods: {
    ...mapActions 'Wallet', ['connect', 'disconnect']
  }

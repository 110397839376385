import {singularMutations} from './functions.coffee'
import {FACTORY_ADDRESS} from '../config.coffee'

import {pickBy, invertBy, invert, mapValues, mapKeys} from 'lodash'

getEvents = (fromBlock, toBlock, Contract) ->
  events = []
  for f in [fromBlock..toBlock] by 5000
    latestBlock = `f + 4999 < toBlock ? f + 4999 : toBlock`
    console.info "#{f}...#{latestBlock}"
    retries = 0
    loop
      try
        batch = await Contract.queryFilter({}, f, latestBlock)
        break
      catch error
        console.error error
        await sleep 1000
        if retries++ > 10
          throw 'Превышено число попыток'

    for {blockNumber: block, event: name, args, transactionHash: tx} in batch
      args = pickBy args, (val, key) -> isNaN +key
      events.push {block, name, args, tx}
  events

export default
  namespaced: true
  state: =>
    blockNumber: 0
    factoryEvents: null
    pairs: []
    events: {}
    lastCheckedBlock: {}
  mutations: {
    ... singularMutations [
      'factoryEvents'
      'pairs'
      'blockNumber'
    ]
    normalizeEvents: (state) ->
      for pair, events of state.events
        for event in events
          for key, value of event.args
            if value.type == "BigNumber"
              event.args[key] = new ethers.BigNumber.from(value) 
    setEvents: (state, {pair, events}) -> state.events[pair] = events
    pushEvents: (state, {pair, events}) -> state.events[pair].push ...events
    lastCheckedBlock: (state, {address, block}) -> state.lastCheckedBlock[address] = block
  }
  actions:
    initialize: ({dispatch, commit, getters}) ->      
      res = await fetch("/events/#{FACTORY_ADDRESS}.json")
      {events, lastCheckedBlock} = await res.json()
      commit 'lastCheckedBlock', address: FACTORY_ADDRESS, block: lastCheckedBlock
      events.push ... await dispatch 'getUpdates', FACTORY_ADDRESS
      commit 'factoryEvents', events
      commit 'pairs', Object.keys(getters.addressOf).sort()
    load: ({dispatch, commit, getters: {addressOf}}, pair) ->
      pairAddress = addressOf[pair]
      res = await fetch("/events/#{pairAddress}.json")
      {events, lastCheckedBlock} = await res.json()
      commit 'lastCheckedBlock', address: pairAddress, block: lastCheckedBlock
      commit 'setEvents', {pair, events}
      commit 'normalizeEvents'
      events = await dispatch 'getUpdates', pairAddress
      commit 'pushEvents', {pair, events}
    update: ({dispatch, commit, getters: {addressOf}}, pair) ->
      pairAddress = addressOf[pair]
      events = await dispatch 'getUpdates', pairAddress
      commit 'pushEvents', {pair, events}
    getBlockNumber: ({commit, rootState: Wallet: defaultProvider: provider}) ->
      commit 'blockNumber', await provider.getBlockNumber()
    getUpdates: ({dispatch, state, commit}, address) ->
      await dispatch 'getBlockNumber'
      start = state.lastCheckedBlock[address] + 1
      end = state.blockNumber
      return [] if start > end 
      commit 'lastCheckedBlock', {address, block: end}
      contract = await dispatch 'contracts/load', address, root:true
      await getEvents(start, end, contract)

  getters:
    parse: ({factoryEvents: events}) ->
      symbolOf = {}
      tokens = {}
      pairObjects = {}
      defaultPairAddress = null
      data = null
      for event in events
        switch event.name
          when 'PairCreated'
            {symbol, contractAddress, baseCurrency, quoteCurrency, hidden} = event.args
            symbolOf[contractAddress] = event.args.symbol
            [base, quote] = event.args.symbol.split('/')
            tokens[base]  = baseCurrency  unless tokens[base]
            tokens[quote] = quoteCurrency unless tokens[quote]
            pairObjects[contractAddress] = {symbol, baseCurrency, quoteCurrency, hidden}
          when 'PairHidden'
            pairObjects[event.args.pair].hidden = true
          when 'PairShowed'
            pairObjects[event.args.pair].hidden = false
          when 'SetAsDefault'
            pairObjects[event.args.pair].default = true
            defaultPairAddress = event.args.pair
          when 'SetData'
            data = event.args.data
          when 'SetPairSettings'
            pairObjects[event.args.pair].settings = event.args.pair.settings
          when 'SetSymbol'
            oldSymbol = pairObjects[event.args.pair].symbol
            pairObjects[event.args.pair].symbol = event.args.symbol
            symbolOf[event.args.pair] = event.args.symbol
            [oldBase, oldQuote] = oldSymbol.split('/')
            [base, quote] = event.args.symbol.split('/')
            tokens[base]  = tokens[oldBase]  unless tokens[base]
            tokens[quote] = tokens[oldQuote] unless tokens[quote]
      {tokens, pairObjects, defaultPairAddress, symbolOf, data}
    symbolOf: (_, {parse: {symbolOf}}) -> symbolOf
    tokens: (_, {parse: {tokens}}) -> tokens
    pairObjects: (_, {parse: {pairObjects}}) -> pairObjects
    defaultPair: (_, {parse: {defaultPairAddress, symbolOf}}) -> symbolOf[defaultPairAddress]
    addressOf: (_, {pairObjects}) -> invert mapValues pairObjects, ({symbol}) -> symbol

    # addressOf: ({factoryEvents: events}) ->
    #   result = {}
    #   for event in events when event.name == 'PairCreated'
    #     result[event.args.symbol] = event.args.contractAddress
    # tokens: ({factoryEvents: events}) ->
    #   result = {}
    #   for event in events when event.name == 'PairCreated'
    #     [base, quote] = event.args.symbol.split('/')
    #     result[base] = event.args.baseCurrency unless result[base]
    #     result[quote] = event.args.quoteCurrency unless result[quote]
    #   result
      

#

import AddressIcon from './AddressIcon.vue'
import config from '../config.coffee'
import { Message } from 'element-ui'

reverseString = (s) ->
  return "" if s == ""
  reverseString(s.substr(1)) + s.charAt(0)

autoCompact = (el, {value}, {context}) ->
  if value and el.scrollWidth > el.clientWidth
    context.autoCompact = true

# BASE_URL = if config.tronLink.network == 'mainnet'
#   "https://tronscan.org/#/address/"
# else
#   "https://#{config.tronLink.network}.tronscan.org/#/address/"

export default
  components: {AddressIcon}
  props:
    value: undefined
    compact: Boolean
    compactIfOverflow: Boolean
    showIcon: Boolean
    showLink: Boolean
    showCopy: Boolean
  data: ->
    autoCompact: false
  directives:
    autoCompact:
      inserted: autoCompact
  computed:
    computedValue: ->
      @value
    reversed: ->
      reverseString(@value)
    href: -> BASE_URL + @value
  methods:
    copyToClipboard: ->
      @$clipboard @value
      Message message: "Адрес скопирован в буфер обмена", type: "success", duration: 1000



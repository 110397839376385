
import {mapState, mapGetters} from 'vuex'
import {verboseSend, repeat} from '../../store/functions.coffee'
import BigNumber from 'bignumber.js'

export default
  data: ->
    new_maxBaseAsset: null
    new_maxBaseValue: null
    new_minBaseAsset: null
    new_minBaseValue: null
    new_maxQuoteAsset: null
    new_maxQuoteValue: null
    new_minQuoteAsset: null
    new_minQuoteValue: null
    new_sellPrice:
      max: null
      min: null
    new_buyPrice:
      max: null
      min: null
  computed: {
    ...mapGetters 'Exchange', [
      'currentPair'
      'maxBaseAsset'
      'maxBaseValue'
      'minBaseAsset'
      'minBaseValue'
      'maxQuoteAsset'
      'maxQuoteValue'
      'minQuoteAsset'
      'minQuoteValue'
      'sellPrice'
      'buyPrice'
      'priceDivisor'
    ]
    ...mapGetters 'Wallet', ['signer']
    ...mapState 'Exchange', ['contract']
    ...mapGetters 'Exchange', ['baseFormat', 'quoteFormat', 'baseParse', 'quoteParse']
  }
  mounted: ->
    @new_maxBaseAsset = @fromBaseUnits @maxBaseAsset
    @new_maxBaseValue = @fromBaseUnits @maxBaseValue
    @new_minBaseAsset = @fromBaseUnits @minBaseAsset
    @new_minBaseValue = @fromBaseUnits @minBaseValue
    @new_maxQuoteAsset = @fromQuoteUnits @maxQuoteAsset
    @new_maxQuoteValue = @fromQuoteUnits @maxQuoteValue
    @new_minQuoteAsset = @fromQuoteUnits @minQuoteAsset
    @new_minQuoteValue = @fromQuoteUnits @minQuoteValue
    @new_sellPrice.min = @fromPrice @sellPrice.min
    @new_sellPrice.max = @fromPrice @sellPrice.max
    @new_buyPrice.min = @fromPrice @buyPrice.min
    @new_buyPrice.max = @fromPrice @buyPrice.max
  methods:
    fromBaseUnits: (value) -> +@baseFormat value.toLocaleString('en', useGrouping: false)
    fromQuoteUnits: (value) -> +@quoteFormat value.toLocaleString('en', useGrouping: false)
    toBaseUnits: (value) -> BigNumber @baseParse(value).toString()
    toQuoteUnits: (value) -> BigNumber @quoteParse(value).toString()
    # fromPrice: (value) -> value.div(@priceDenominator).toNumber()
    # toPrice: (value) -> BigNumber(value).times(@priceDenominator)
    # fromSun: (value) -> +tronWeb.fromSun value
    # toSun: (value) -> BigNumber tronWeb.toSun value
    fromPrice: (value) -> value.div(@priceDivisor).toNumber()
    toPrice: (value) -> BigNumber(value).times(@priceDivisor)
    setLimitBuyRanges: ->
      minValue = @toBaseUnits( @new_minBaseValue || 0).toString()
      maxValue = @toBaseUnits( @new_maxBaseValue || 0).toString()
      minAsset = @toQuoteUnits( @new_minQuoteAsset || 0).toString()
      maxAsset = @toQuoteUnits( @new_maxQuoteAsset || 0).toString()
      minPrice = @toPrice( @new_buyPrice.min || 0).toString()
      maxPrice = @toPrice( @new_buyPrice.max || 0).toString()
      p "SET limit BUY ranges:", {minAsset, maxAsset, minValue, maxValue, minPrice, maxPrice}
      verboseSend @contract.connect(@signer).setLimitBuyRanges(minAsset, maxAsset, minValue, maxValue, minPrice, maxPrice)
    setLimitSellRanges: ->
      minAsset = @toBaseUnits( @new_minBaseAsset || 0).toString()
      maxAsset = @toBaseUnits( @new_maxBaseAsset || 0).toString()
      minValue = @toQuoteUnits( @new_minQuoteValue || 0).toString()
      maxValue = @toQuoteUnits( @new_maxQuoteValue || 0).toString()
      minPrice = @toPrice( @new_sellPrice.min || 0).toString()
      maxPrice = @toPrice( @new_sellPrice.max || 0).toString()
      p "SET limit SELL ranges:", {minAsset, maxAsset, minValue, maxValue, minPrice, maxPrice}
      verboseSend @contract.connect(@signer).setLimitSellRanges(minAsset, maxAsset, minValue, maxValue, minPrice, maxPrice)



# Exchange

import config from '../config.coffee'
import {
  singularMutations
  repeat
} from './functions.coffee'

import BigNumber from 'bignumber.js'

# import {address2hex} from '../multicall.coffee'

import {zipObject} from 'lodash'

import ABI from '../abi/OrderBook.sol/OrderBook.json'

OUTPUTS = {
  numberSettings: ['baseCurrencyUnit', 'quoteCurrencyUnit', 'priceDivisor', 'quoteDivisor', 'baseDivisor']
  rangeSettings: ['_maxBaseAsset', '_maxBaseValue', '_minBaseAsset', '_minBaseValue', '_maxQuoteAsset', '_maxQuoteValue', '_minQuoteAsset', '_minQuoteValue', '_minSellPrice', '_maxSellPrice', '_minBuyPrice', '_maxBuyPrice']
  baseCurrencyInfo: ['name', 'symbol', 'decimals', 'tokenAddress']
  quoteCurrencyInfo: ['name', 'symbol', 'decimals', 'tokenAddress']
}

{
  parseUnits,
  formatUnits
} = ethers.utils

export default
  namespaced: true
  state: =>
    currentPair: null
    initialized: false
    loading: false
    loadingUpdates: false
    stopUpdates: true
    contract: null
    events: []
    numberSettings: null
    rangeSettings: null
    baseCurrencyInfo: null
    quoteCurrencyInfo: null
    checksum: null
    timeout: null
    settings:
      priceBase: BigNumber(1)
    limitBuyRanges:
      minPrice: BigNumber(1)
  mutations: {
    initialization: (state) -> state.initialized = true
    ... singularMutations [
      'currentPair'
      'loading'
      'contract'
      'events'
      'numberSettings'
      'rangeSettings'
      'baseCurrencyInfo'
      'quoteCurrencyInfo'
      'checksum'
      'timeout'
      'loadingUpdates'
      'stopUpdates'
    ]
    parameters: (state, params) -> state.swap.parameters = params
  }
  actions:
    load: ({ state, dispatch }, {pair}) -> dispatch (if state.initialized and state.currentPair == pair then 'reload' else 'initialize'), {pair}
    initialize: ({state, commit, dispatch, rootGetters: {"Events/addressOf": addressOf}}, {pair}) ->
      return if state.loading
      # console.info 'loading start'
      commit('loading', true) if state.currentPair != pair
      commit('currentPair', pair)
      await Promise.all [
        dispatch('getContractEvents')
        dispatch('contracts/load', addressOf[state.currentPair], root: true).then (c) ->
          commit 'contract', c
          dispatch('getContractState')
      ]
      # console.info 'loading end'
      commit('loading', false)
      commit('initialization')
      dispatch 'startUpdates'
    reload: ({ commit, dispatch, state }, {pair}) ->
      # console.info 123
      return if state.loading
      commit('loading', true) if state.currentPair != pair
      commit('currentPair', pair)
      await Promise.all [
        dispatch('getContractEvents')
        dispatch('getContractState')
      ]
      commit('loading', false)
    silentReload: ({dispatch}) -> Promise.all [
      dispatch('getContractEvents')
      dispatch('getContractState')
    ]
    update: ({state, commit, dispatch, rootState}) ->
      return if state.loadingUpdates
      commit 'loadingUpdates', true
      contract = state.contract
      commitResult = (name) -> (value) -> commit(name, value)
      await Promise.all [
        dispatch 'Events/update', state.currentPair, root: true
        commit 'events', rootState.Events.events[state.currentPair]
        Promise.all [
          repeat(-> contract.rangeSettings()).then commitResult 'rangeSettings'
          repeat(-> contract.checksum()).then commitResult 'checksum'
        ]
      ]
      commit 'loadingUpdates', false
    getContractEvents: ({commit, dispatch, state, rootState, rootGetters: {"Events/addressOf": addressOf}}) ->
      await dispatch 'Events/load', state.currentPair, root: true
      # p rootState.Events.events[state.currentPair]
      commit 'events', rootState.Events.events[state.currentPair]
    getContractState: ({dispatch}) -> Promise.all [
      dispatch('getContractCommonState')
      # dispatch('getContractUserState')
    ]
    getContractCommonState: ({state, commit, rootState, rootGetters}) ->
      # return
      multicall = rootState.contracts.multicall
      contract = state.contract
      commitResult = (name) -> (value) -> commit(name, value)

      methods = ['numberSettings', 'rangeSettings', 'baseCurrencyInfo', 'quoteCurrencyInfo', 'checksum']

      calls = [{
        reference: 'Pair'
        contractAddress: contract.address
        abi: ABI
        calls: [
          ...(
            for name in methods
              reference: name
              methodName: name
          )
        ]}
      ]

      {results, blockNumber} = await multicall.call calls
      
      for {success, returnValues, reference} in results.Pair.callsReturnContext when success
        outputs = OUTPUTS[reference]
        if outputs
          returnValues = returnValues.map (el) ->
            if el.type == 'BigNumber'
              new ethers.BigNumber.from(el)
            else
              el
          returnValues = zipObject outputs, returnValues
        else
          returnValues = returnValues[0] if returnValues.length <= 1 && !(Array.isArray(returnValues[0]))
        commit reference, returnValues

    getContractUserState: ({state, commit, rootState, getters: {userAddress}}) ->
      # state.contract.ordersOf(userAddress).call().then ({list}) -> commit 'orders', list
    startUpdates: ({state, commit, dispatch}) ->
      # console.info 'start checking updates'
      commit 'stopUpdates', false
      dispatch('checkUpdates') if state.timeout == null
    checkUpdates: ({state, commit, dispatch}) ->
      # console.info 'check updates', state.stopUpdates
      return if state.stopUpdates
      commit 'timeout', setTimeout (->
        checksum = await repeat(-> state.contract.checksum())
        await dispatch 'update' if checksum.toString() != state.checksum.toString()
        await dispatch 'checkUpdates'
        return
      ), 3000
    stopUpdates: ({state, commit}) ->
      clearTimeout state.timeout
      commit 'timeout', null
      commit 'stopUpdates', true
  getters:
    userAddress: -> arguments[2].Wallet.account.address
    currentPair: ({currentPair}) -> currentPair.split('/')
    bids: (_, {orderBook: {buy: list}, priceDivisor, currency}) ->
      prices = {}
      result = []
      decimals = Math.ceil(Math.log10(priceDivisor.toNumber()))
      for id, {asset, value, timestamp} of list
        price = asset.div(currency.quote.divisor).div(value.div(currency.base.divisor))#.shiftedBy(currency.quote.decimals - currency.base.decimals))
        price = price.times(priceDivisor).integerValue().div(priceDivisor)
        # p price
        price = price.toNumber().toFixed(decimals)
        prices[price] ||= {volume: BigNumber(0), total: BigNumber(0), orders: []}
        prices[price].total = prices[price].total.plus(asset)
        prices[price].volume = prices[price].volume.plus(value)
        prices[price].orders.push {id, asset, value, timestamp}
      for price, {total, volume, orders} of prices
        total = total.div(currency.quote.divisor).toNumber()
        volume = volume.div(currency.base.divisor).toNumber()
        result.push {price, total, volume, orders}
      for row in result
        row.orders.sort (a,b) -> a.timestamp - b.timestamp
      result.sort (a,b) -> b.price - a.price
    asks: (_, {orderBook: {sell: list}, priceDivisor, currency}) ->
      prices = {}
      result = []
      decimals = Math.ceil(Math.log10(priceDivisor.toNumber()))
      for id, {asset, value, timestamp} of list
        price = value.div(currency.quote.divisor).div(asset.div(currency.base.divisor))
        price = price.times(priceDivisor).integerValue().div(priceDivisor)
        price = price.toNumber().toFixed(decimals)
        prices[price] ||= {volume: BigNumber(0), total: BigNumber(0), orders: []}
        prices[price].total = prices[price].total.plus(value)
        prices[price].volume = prices[price].volume.plus(asset)
        prices[price].orders.push {id, asset, value, timestamp}
      for price, {total, volume, orders} of prices
        total = total.div(currency.quote.divisor).toNumber()
        volume = volume.div(currency.base.divisor).toNumber()
        result.push {price, total, volume, orders}
      for row in result
        row.orders.sort (a,b) -> a.timestamp - b.timestamp
      result.sort (a,b) -> a.price - b.price
    minAsk: (_, {asks, priceDivisor}) -> BigNumber(asks[0]?.price or 0).times(priceDivisor)
    maxBid: (_, {bids, priceDivisor}) -> BigNumber(bids[0]?.price or 0).times(priceDivisor)
    orders: (_, {orderBook, userAddress, priceDivisor, currency, baseFormat, quoteFormat}) ->
      decimals = Math.ceil(Math.log10(priceDivisor.toNumber()))
      [
        ...Object.values(orderBook.buy).filter(({trader}) -> trader == userAddress).map ({asset: total, value: volume, id}) ->
          # console.info total.toString(), volume.toString()
          price = total.div(currency.quote.divisor).div(volume.div(currency.base.divisor))
          price = price.times(priceDivisor).integerValue().div(priceDivisor).toNumber().toFixed(decimals)
          volume = baseFormat(volume.toString())
          total = quoteFormat(total.toString())
          {id, type: 'buy', volume, price, total}
        ...Object.values(orderBook.sell).filter(({trader}) -> trader == userAddress).map ({asset: volume, value: total, id}) ->
          # console.info total.toString(), volume.toString()
          price = total.div(currency.quote.divisor).div(volume.div(currency.base.divisor))
          price = price.times(priceDivisor).integerValue().div(priceDivisor).toNumber().toFixed(decimals)
          volume = baseFormat(volume.toString())
          total = quoteFormat(total.toString())
          {id, type: 'sell', volume, price, total}
      ].sort (a,b) -> b.id - a.id
    trades: ({events},{priceDivisor, currency, baseFormat, quoteFormat}) ->
      result = []
      decimals = Math.ceil(Math.log10(priceDivisor.toNumber()))
      for {name, args: {asset, seller, buyer, value, timestamp}} in events
        switch name
          when "MarketBuy"
            total = BigNumber(asset.toString())
            volume = BigNumber(value.toString())
            price = total.div(currency.quote.divisor).div(volume.div(currency.base.divisor))
            price = price.times(priceDivisor).integerValue().div(priceDivisor).toNumber().toFixed(decimals)
            total = quoteFormat(asset)
            volume = baseFormat(value)
            type = "buy"
            trader = buyer
            timestamp = timestamp.toNumber()*1000
            result.push {timestamp, type, total, volume, price, trader}
          when "MarketSell"
            total = BigNumber(value.toString())
            volume = BigNumber(asset.toString())
            price = total.div(currency.quote.divisor).div(volume.div(currency.base.divisor))
            price = price.times(priceDivisor).integerValue().div(priceDivisor).toNumber().toFixed(decimals)
            total = quoteFormat(value)
            volume = baseFormat(asset)
            type = "sell"
            trader = seller
            timestamp = timestamp.toNumber()*1000
            result.push {timestamp, type, total, volume, price, trader}
      result.sort (a,b) -> b.timestamp - a.timestamp
    myTrades: (_, {trades, userAddress}) -> trades.filter ({trader}) -> trader == userAddress
    # events: ({currentPair},g,{Events: {events}}) -> events[currentPair]
    orderBook: ({events}) ->
      orders = {buy: {}, sell: {}}
      for {name, args} in events
        result = args
        timestamp = args.timestamp
        switch name
          when "LimitBuy"
            {asset, value, trader, id} = result
            asset = BigNumber(asset.toString())
            value = BigNumber(value.toString())
            orders.buy[result.id] = {id, asset, value, trader}
            orders.buy[result.id].timestamp ||= timestamp
          when "LimitSell"
            {asset, value, trader, id} = result
            asset = BigNumber(asset.toString())
            value = BigNumber(value.toString())
            orders.sell[result.id] = {id, asset, value, trader}
            orders.sell[result.id].timestamp ||= timestamp
          when "Cancel"
            delete orders.buy[result.id]
            delete orders.sell[result.id]
          when "Close"
            delete orders.buy[result.id]
            delete orders.sell[result.id]
      orders
    buyIds: (_, {bids}) ->
      result = []
      for {orders} in bids
        for {id} in orders
          result.push id
      result
    sellIds: (_, {asks}) ->
      result = []
      for {orders} in asks
        for {id} in orders
          result.push id
      result
    priceDivisor: ({numberSettings: {priceDivisor}}) -> BigNumber(priceDivisor.toString())
    currency: ({numberSettings, baseCurrencyInfo, quoteCurrencyInfo}) ->
      base:
        unit: BigNumber(numberSettings.baseCurrencyUnit.toString())
        divisor: BigNumber(numberSettings.baseDivisor.toString())
        symbol: baseCurrencyInfo.symbol
        name: baseCurrencyInfo.name
        address: baseCurrencyInfo.tokenAddress
        decimals: baseCurrencyInfo.decimals.toNumber()
      quote:
        unit: BigNumber(numberSettings.quoteCurrencyUnit.toString())
        divisor: BigNumber(numberSettings.quoteDivisor.toString())
        symbol: quoteCurrencyInfo.symbol
        name: quoteCurrencyInfo.name
        address: quoteCurrencyInfo.tokenAddress
        decimals: quoteCurrencyInfo.decimals.toNumber()
    sellPrice: ({rangeSettings}) ->
      min: BigNumber(rangeSettings._minSellPrice.toString())
      max: BigNumber(rangeSettings._maxSellPrice.toString())
    buyPrice: ({rangeSettings}) ->
      min: BigNumber(rangeSettings._minBuyPrice.toString())
      max: BigNumber(rangeSettings._maxBuyPrice.toString())
    maxBaseAsset: ({rangeSettings}) -> BigNumber(rangeSettings._maxBaseAsset.toString())
    maxBaseValue: ({rangeSettings}) -> BigNumber(rangeSettings._maxBaseValue.toString())
    minBaseAsset: ({rangeSettings}) -> BigNumber(rangeSettings._minBaseAsset.toString())
    minBaseValue: ({rangeSettings}) -> BigNumber(rangeSettings._minBaseValue.toString())
    maxQuoteAsset: ({rangeSettings}) -> BigNumber(rangeSettings._maxQuoteAsset.toString())
    maxQuoteValue: ({rangeSettings}) -> BigNumber(rangeSettings._maxQuoteValue.toString())
    minQuoteAsset: ({rangeSettings}) -> BigNumber(rangeSettings._minQuoteAsset.toString())
    minQuoteValue: ({rangeSettings}) -> BigNumber(rangeSettings._minQuoteValue.toString())

    baseFormat: (_, {currency: base: {decimals}}) -> (units) -> formatUnits units, decimals
    baseParse: (_, {currency: base: {decimals}}) -> (value) -> parseUnits value.toLocaleString('en', useGrouping: false), decimals
    quoteFormat: (_, {currency: quote: {decimals}}) -> (units) -> formatUnits units, decimals
    quoteParse: (_, {currency: quote: {decimals}}) -> (value) -> parseUnits value.toLocaleString('en', useGrouping: false), decimals





import Vue from 'vue'
import {
  Alert
  Breadcrumb
  BreadcrumbItem
  Button
  ButtonGroup
  Card
  Col
  Collapse
  CollapseItem
  Dialog
  Divider
  Drawer
  Dropdown
  DropdownItem
  DropdownMenu
  Form
  FormItem
  InfiniteScroll
  Input
  InputNumber
  Link
  Loading
  Menu
  MenuItem
  Option
  Pagination
  Popconfirm
  Popover
  Progress
  Radio
  RadioButton
  RadioGroup
  Row
  Select
  Slider
  Submenu
  Switch
  Table
  TableColumn
  TabPane
  Tabs
  TimePicker
  Tooltip
} from 'element-ui'
import lang from 'element-ui/lib/locale/lang/en'
import locale from 'element-ui/lib/locale'


locale.use lang
Vue.use cmp for cmp in [
  Alert
  Breadcrumb
  BreadcrumbItem
  Button
  ButtonGroup
  Card
  Col
  Collapse
  CollapseItem
  Dialog
  Divider
  Drawer
  Dropdown
  DropdownItem
  DropdownMenu
  Form
  FormItem
  InfiniteScroll
  Input
  InputNumber
  Link
  Loading
  Menu
  MenuItem
  Option
  Pagination
  Popconfirm
  Popover
  Progress
  Radio
  RadioButton
  RadioGroup
  Row
  Select
  Slider
  Submenu
  Switch
  Table
  TableColumn
  TabPane
  Tabs
  TimePicker
  Tooltip
]
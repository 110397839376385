import { render, staticRenderFns } from "./NavigationShort.vue?vue&type=template&id=2f02e0a6&scoped=true&lang=pug&"
import script from "./NavigationShort.vue?vue&type=script&lang=coffee&"
export * from "./NavigationShort.vue?vue&type=script&lang=coffee&"
import style0 from "./NavigationShort.vue?vue&type=style&index=0&id=2f02e0a6&prod&lang=stylus&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2f02e0a6",
  null
  
)

export default component.exports